$spacer: 1rem;
$spacers: () !default;

// custom spacer
$spacers: (
  6: (
    $spacer * 2.5
  ),
  7: (
    $spacer * 2
  )
);

// merge custom spacer with defaults
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);
