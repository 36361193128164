.btn {
  position: relative;
  height: 48px;
  font-size: 1rem;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// TODO: this will probably become obsolete
@mixin inset_circle($background_color) {
  content: '';
  position: absolute;
  top: 50%;
  left: 13px;
  width: 28px;
  height: 28px;
  margin-top: -14px;
  border-radius: 50%;
  background-color: $background_color;
}

// TODO: this will probably become obsolete
@mixin inset_circle_reverse($background_color) {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $background_color;
}

.btn .swiper-circle-wrapper {
  position: absolute;
  height: 100%;
  width: 50px;
  transition: right 0.1s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------
- BUTTON primary
------------------------------------*/
.btn-primary {
  color: $white;
  background-color: $primary_t1;
  border: 0 none;

  &.shadow {
    box-shadow: 0px 4px 0px #aadef0;
  }

  &.inset-circle::after {
    @include inset_circle($white);
  }

  & .slider-circle {
    @include inset_circle_reverse($white);
  }
}

.btn-primary:hover {
  background-color: $primary_t1;
  color: $white;
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: $primary_t1;
  box-shadow: none !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: $white;
  background-color: $primary_t1;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary_t1;
  outline: none;
}

/*------------------------------------
- BUTTON outline-primary
------------------------------------*/
.btn-outline-primary {
  color: $primary_t1;
  background-color: $white;
  border: 0 none;
  box-shadow: inset 0px 0px 0px 3px $primary_t1;

  &.shadow {
    box-shadow: 0px 4px 0px #aadef0;
  }

  &.inset-circle::after {
    @include inset_circle($primary_t1);
  }

  & .slider-circle {
    @include inset_circle_reverse($primary_t1);
  }
}

.btn-outline-primary:hover {
  color: $primary_t1;
  background-color: $white;
  box-shadow: inset 0px 0px 0px 3px $primary_t1;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background-color: $white;
  box-shadow: 0 0 0 0.2rem $primary_shadow;
  box-shadow: inset 0px 0px 0px 3px $primary_t1;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primary_t1;
  background-color: $white;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $primary_t1;
  background-color: $white;
  box-shadow: inset 0px 0px 0px 3px $primary_t1;
  outline: none;
}

/*------------------------------------
- BUTTON secondary
------------------------------------*/

.btn-secondary {
  color: $white;
  background-color: $secondary_t1;
  border: 0 none;

  &.shadow {
    box-shadow: 0px 4px 0px #aadef0;
  }

  &.inset-circle::after {
    @include inset_circle($white);
  }
}

.btn-secondary:hover {
  background-color: $secondary_t1;
  color: $white;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $white;
  background-color: $secondary_t1;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: $secondary_t1;
  outline: none;
}

/*------------------------------------
- BUTTON outline-secondary
------------------------------------*/
.btn-outline-secondary {
  color: $white;
  background-color: $primary_t1;
  border: 0 none;
  box-shadow: inset 0px 0px 0px 3px $white;

  &.shadow {
    box-shadow: 0px 4px 0px #aadef0;
  }

  &.inset-circle::after {
    @include inset_circle($white);
  }

  & .slider-circle {
    @include inset_circle_reverse($white);
  }
}

.btn-outline-secondary:hover {
  color: $white;
  background-color: $primary_t1;
  box-shadow: inset 0px 0px 0px 3px $white;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  background-color: $primary_t1;
  box-shadow: 0 0 0 0.2rem $primary_shadow;
  box-shadow: inset 0px 0px 0px 3px $white;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $white;
  background-color: $primary_t1;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $white;
  background-color: $primary_t1;
  box-shadow: inset 0px 0px 0px 3px $white;
  outline: none;
}

/*------------------------------------
- BUTTON dark
------------------------------------*/
.btn-dark {
  color: $white;
  background-color: $black;
  border: 0 none;
}

.btn-dark:hover {
  background-color: $black;
  color: $white;
}

.btn-dark:focus,
.btn-dark.focus {
  color: $white;
  box-shadow: none !important;
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: $white;
  background-color: $black;
  opacity: 1;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: $black;
  outline: none;
}

/*------------------------------------
- BUTTON light
------------------------------------*/
.btn-light {
  background-color: $white;
  border: 0 none;
  color: $primary_t1;

  &.shadow {
    box-shadow: 0px 4px 0px #aadef0;
  }

  &.inset-circle::after {
    @include inset_circle($primary_t1);
  }

  & .slider-circle {
    @include inset_circle_reverse($primary_t1);
  }
}

.btn-light:hover {
  background-color: $white;
  color: $primary_t1;
}

.btn-light:focus,
.btn-light.focus {
  color: $primary_t1;
  box-shadow: none !important;
}

.btn-light.disabled,
.btn-light:disabled {
  color: $primary_t1;
  background-color: $white;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: $primary_t1;
  background-color: $white;
  outline: none;
}
