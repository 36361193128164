/*-----------------------------
Desktop / mobile
*/
.is-non-mobile {
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
}

.hide-on-non-mobile {
  display: flex;
  @media (min-width: 560px) {
    display: none !important;
  }
}

/*-----------------------------
Miscellaneous
*/
.timer {
  font-size: 3.5rem;
}

.text-blur {
  color: transparent;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.post-install-notification {
  z-index: 2;
}

.postMessage {
  max-height: 88px;
  color: $black;
  opacity: 0.6;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.ios-share-button {
  box-sizing: content-box;
  height: 80px;
  width: 80px;
  background: #dff2f1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-to-install-note {
  background: #dff2f1;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  margin-bottom: 24px;

  p {
    font-size: 12px;
  }

  svg {
    width: 60px;
    margin-right: 12px;
  }
}

/*-----------------------------
Overflow x layout
*/
.pull-left {
  left: -1rem;
}

/*-----------------------------
Share banner image
*/
.banner-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 240px;
  width: 100%;
  &.offline {
    background-image: url('/images/back-in-2-dnd.png');
  }
  &.online {
    background-image: url('/images/im-back.png');
  }
}

/*-----------------------------
Form
*/
textarea {
  border: none !important;
  padding: 5px 0px !important;
  resize: none;
  &:focus {
    box-shadow: none !important;
  }
  &.is-invalid {
    background: none !important;
  }
}

input.form-control {
  border-radius: 4px;
  border: 1px solid $white;
  box-shadow: none;
  background: transparent;
  &.is-invalid {
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
  &::placeholder {
    color: $white;
  }
  &:focus {
    outline: none;
    border: 1px solid $white;
    box-shadow: none;
    background: transparent;
  }
}

.invalid-feedback {
  &.contained {
    bottom: -16px;
    left: 0;
  }
}

.form-info-box {
  background: #dff2f1;
  padding: 12px 19px 15px 19px;
  border-radius: 16px;
  p,
  span {
    font-size: 12px;
    opacity: 0.8;
  }
  // margin-bottom: 24px;
}

/*-----------------------------
Horizontal rule
*/
.separator {
  position: relative;
  width: 100%;
  height: 0.25rem;
  margin: 1rem 0;
  hr {
    position: absolute;
    width: 100vw;
    left: -1.5rem;
    margin: 0;
  }
  &.thick > hr {
    height: 0.25rem;
  }
}

/*-----------------------------
Share icon overrides
*/
.share-icons {
  > div {
    margin: 0 0.25rem;
    &:nth-child(1) {
      margin-left: 0;
      button {
        background-color: #1877f2 !important;
      }
    }
    &:nth-child(2) {
      button {
        background-color: #29aced !important;
      }
    }
    &:nth-child(3) {
      margin-right: 0;
      button {
        background-color: #34d366 !important;
      }
    }

    button {
      pointer-events: none;
      width: 100%;
      border-radius: 40px;
      svg:nth-child(1) {
        display: inline;
      }
      svg:nth-child(2) {
        display: none;
      }
      // display checkIcon svg when clicked
      &.icon-pressed {
        svg:nth-child(1) {
          display: none;
        }
        svg:nth-child(2) {
          display: inline;
        }
      }
    }
  }
}

/*-----------------------------
Alert
*/
.helper {
  border-radius: 8px;
  border: 0 none;
  padding: 1rem !important;
  margin-bottom: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.13);
}

/*-----------------------------
Dialogue
*/
.dialogue-container {
  z-index: 2;
  position: relative;
  .dialogue-container-inner {
    position: absolute;
    padding: 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(33, 71, 89, 0.3);
  }
}

.dialogue-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &.primary {
    background-color: rgba(0, 158, 216, 1);
  }
  &.white {
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.visible {
    bottom: 0;
  }
}

/*-----------------------------
Intro panel buttons
*/
.intro-buttons {
  margin-left: -40px;
  background-color: $white;
  padding: 1.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100vw;
  box-shadow: 0px -4px 16px rgba(33, 71, 89, 0.3);
  position: sticky;
  bottom: 0;
}

.help-text-valid {
  color: $primary_t1;
  font-size: 12px;
}

.help-text-invalid {
  color: $red;
  font-size: 12px;
  opacity: 0.6;
}
