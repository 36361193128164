@import './bootstrap.scss';
@import './modal.scss';
@import './custom.scss';

@font-face {
  font-family: 'Circular';
  src: url('/fonts/lineto-circular-black-s.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Circular-bold';
  src: url('/fonts/lineto-circular-bold-s.woff');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Circular-book';
  src: url('/fonts/lineto-circular-book-s.woff');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Circular-book, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  background-color: $primary_t1;
  // allow only these native gestures
  // suppress pan-x for fat thumbs, particularly when swiping buttons
  touch-action: pan-y pinch-zoom;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
.btn {
  font-family: Circular-bold, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a,
a:hover {
  color: $primary_t1;
}

* {
  box-sizing: border-box;
}
