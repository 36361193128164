/*------------------------------------
- COLOR primary
------------------------------------*/
$primary_t1: #009ed8;
$primary_t2: #00699b;
$primary_t3: #6dd0ff;
$primary_t4: #86d8ff;
$primary_t5: #003853;
$primary_t6: #005e8b;
$primary_t7: #0073aa;
$primary_t8: #001620;
$primary_t9: #9bdeff;
$secondary_t1: #549cc0;
$black: #1d292e;
$red: #D70015;

$primary_shadow: rgba(0, 140, 206, 0.5);

.alert-primary {
  color: $primary_t5;
  background-color: $primary_t9;
  border-color: $primary_t4;
}

.alert-primary hr {
  border-top-color: $primary_t3;
}

.alert-primary .alert-link {
  color: $primary_t8;
}

.badge-primary {
  color: $white;
  background-color: $primary_t1;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: $white;
  background-color: $primary_t2;
}

.bg-primary {
  background-color: $primary_t1 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: $primary_t2 !important;
}

.border-primary {
  border-color: $primary_t1 !important;
}

.list-group-item-primary {
  color: $primary_t5;
  background-color: $primary_t4;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: $primary_t5;
  background-color: $primary_t3;
}

.list-group-item-primary.list-group-item-action.active {
  color: $white;
  background-color: $primary_t5;
  border-color: $primary_t5;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: $primary_t4;
}

.table-hover .table-primary:hover {
  background-color: $primary_t3;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: $primary_t3;
}

.text-primary {
  color: $primary_t1 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: $primary_t2 !important;
}

/*------------------------------------
misc
------------------------------------*/
.bg-primary-outline {
  background: transparent;
  border: 1px solid $primary_t1;
  color: $primary_t1;
}

.text-black {
  color: $black !important;
}
