@import './bootstrap-extend.scss';

@import '~bootstrap/scss/bootstrap';

// Custom brand colors
@import './colors.scss';

// Custom buttons
@import './buttons.scss';

// additional bootstrap styles
.rounded-4 {
  border-radius: 0.75rem;
}

.rounded-5 {
  border-radius: 1rem;
}

.badge {
  padding: 0.25em 1em 0.15em 1em;
}
